'use client'
import heroSvg from '@jobmojito/admin-app/(landing)/landing/images/background-faqs.jpg'
import PageCard from '@jobmojito/admin-app/components/core/PageCard'
import { Link } from '@nextui-org/link'
import Image from 'next/image'
import React from 'react'
import { MerchantType } from './RegisterMain'
import { RegisterSelector } from './registerSelector'
import { RegisterStep1 } from './registerStep1Interview'
import { RegisterStep2 } from './registerStep2'
import { RegisterStep3 } from './registerStep3'
import { RegisterStepSubscribe } from './registerStepSubscribe'
import { StepsComponent } from './steps'

export default function RegisterInterview({
  goToSubscribe = false,
  onChange,
  subMerchant,
  name,
  email,
}: {
  goToSubscribe?: boolean
  onChange: (range: MerchantType) => void
  subMerchant: boolean
  name?: string
  email?: string
}) {
  const [currentStep, setCurrentStep] = React.useState(1)
  const [nextStepAvailable, setNextStepAvailable] = React.useState(true)

  function processNextStep() {
    setCurrentStep(currentStep + 1)
  }

  function processStepBack(step: number) {
    setCurrentStep(step)
  }

  return (
    <div
      className={
        'w-full flex flex-col items-center relative ' +
        (subMerchant === false ? 'mt-[4rem]' : '')
      }
    >
      <Image
        className={'-mt-10 pointer-events-none'}
        alt="Background Image"
        src={heroSvg}
        fill
        style={{
          objectFit: 'contain',
          scale: 1,
        }}
      />
      <PageCard className={'max-w-5xl'} isVisible={!subMerchant}>
        <div className="flex flex-col justify-left text-left gap-4">
          {goToSubscribe ? (
            <>
              {/*<StepsComponent currentStep={3} changeStep={processStepBack} type='interview' />*/}
              <div className="text-xl font-thin">Subscription needed</div>
              <div className="w-full text-left text-black ">
                To continue using our platform, a subscription is required.
                <br />
                If you have any special requests or would like to chat with us,
                please{' '}
                <Link href="https://jobmojito.com/support" target="_blank">
                  reach out
                </Link>
                .
                {/*<ul className='list-disc ml-5 grid grid-cols-1 lg:grid-cols-2 gap-x-5'>
                            
                            <li>To start using our tool, you need an active subscription.</li>
                            <li>We won't charge you until the trial period ends.</li>
                            <li>You can cancel at any time.</li>
                            <li>All subscriptions are managed by our trusted partner <Link href='https://stripe.com' target='_blank'>stripe.com</Link></li>
                        </ul>*/}
              </div>
              <RegisterStepSubscribe />
            </>
          ) : (
            <>
              {currentStep === 1 ? (
                <RegisterSelector
                  type="interview"
                  onChange={(type) => {
                    onChange(type)
                  }}
                />
              ) : (
                <StepsComponent
                  currentStep={currentStep}
                  changeStep={processStepBack}
                  type="interview"
                />
              )}

              {currentStep === 1 && (
                <RegisterStep1
                  completedStep={() => {
                    processNextStep()
                  }}
                  subMerchant={subMerchant}
                />
              )}
              {currentStep === 2 && (
                <RegisterStep2
                  completedStep={() => {
                    processNextStep()
                  }}
                  type="interview"
                  subMerchant={subMerchant}
                  name={name}
                  email={email}
                />
              )}
              {/*{((currentStep===3) && <RegisterStepSubscribe /> )}*/}
              {currentStep === 3 && <RegisterStep3 />}
            </>
          )}
        </div>
      </PageCard>
    </div>
  )
}
