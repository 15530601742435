import React, { useEffect } from 'react'
import { PricingTableResponse } from '@jobmojito/admin-app/types/collection'

const StripePricingTable = ({
  pricingTableResonse,
}: {
  pricingTableResonse: PricingTableResponse
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': pricingTableResonse.pricing_table_id,
    'publishable-key': pricingTableResonse.publishable_key,
    'customer-session-client-secret': pricingTableResonse.client_secret,
  })
}

export default StripePricingTable
