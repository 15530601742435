import { Button } from '@nextui-org/button'
import NextImage from 'next/image'
import imageInterview from './assets/jobmojito-interview.png'

export function RegisterStep1({
  completedStep,
  subMerchant,
}: {
  completedStep: () => void
  subMerchant: boolean
}) {
  return (
    <>
      <div className="mt-2 mb-5 gap-5">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-10">
          <div className="col-span-2">
            <div className="text-xl text-black font-bold underline">
              <span className="font-light">Create</span> AI Interview platform
            </div>
            <div className="mt-2 text-black">
              Future of recruitment with AI Avatars, that can answer candidate’s
              questions, with unbiased scoring, powerful platform behind.
            </div>

            <ul className="text-xs text-black list-disc ml-5 mt-5">
              <li>
                Utilize our pre-defined job position catalog, leverage AI to
                create new listings, or craft custom interviews.
              </li>
              <li>Create an impressive public Career page.</li>
              <li>
                Efficiently manage submitted interviews, candidates, and the
                selection process for the next round.
              </li>
              <li>
                Save up to 90% of your effort during screening interviews!
              </li>
              <li>
                Customize branding for avatars, portals, and user experience
              </li>
            </ul>

            <div className="mt-10">
              Please follow the onboarding checklist, and should you have any
              queries, don't hesitate to contact us via the{' '}
              <a
                href="https://jobmojito.com/support"
                target="_blank"
                className="underline text-primary"
              >
                support page
              </a>
              .
            </div>
          </div>
          <div className="hidden md:flex">
            <NextImage alt="Background Image" src={imageInterview} />
          </div>
        </div>
      </div>
      <Button
        color="primary"
        onClick={() => {
          completedStep()
        }}
      >
        Next
      </Button>
    </>
  )
}
