'use client'
import getClientSupabase from '@jobmojito/app/utils/getClientSupabase'
import { useState, useEffect } from 'react'
import { useError } from '@jobmojito/components/providers/ErrorProvider'
import StripePricingTableElement from './StripePricingTableElement'
import { PricingTableResponse } from '@jobmojito/admin-app/types/collection'
import { Spinner } from '@nextui-org/react'

export default function StripePricingTable() {
  const { showError } = useError()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [pricingTableData, setPricingTableData] = useState<
    PricingTableResponse | undefined
  >()

  useEffect(() => {
    if (!loaded) {
      const supabase = getClientSupabase()
      supabase.auth.refreshSession().then((refreshSession) => {
        supabase.functions
          .invoke('stripe-pricing-table', {
            body: { merchant_payment: true },
            method: 'POST',
          })
          .then((res) => {
            res.error?.context.json().then(({ error }: { error: string }) => {
              showError(error, 'error')
            })
            if (res && res.data && res.data.client_secret) {
              setPricingTableData(res.data)
            }
            setLoaded(true)
          })
      })
    }
  }, [])

  return (
    <>
      {pricingTableData === undefined ? (
        <div className="w-full text-center text-xl font-light mt-8">
          <Spinner size="sm" color="secondary" className="mr-2" />
          Loading
        </div>
      ) : (
        <StripePricingTableElement pricingTableResonse={pricingTableData} />
      )}
    </>
  )
}
